export default {
  EN: {
    'finance.company': 'Beneficiary Name(公司名称)',
    'finance.companyAddress': 'Company Address(公司地址)',
    'finance.companyAddress.placeholder': '公司地址',
    'finance.taxNumber': 'Swift Code(银行国际代码)',
    'finance.taxNumber.placeholder': '银行国际代码',
    'finance.accountBank': 'Bank Name(开户行名称)',
    'finance.accountCode': 'Account Number/IBAN(银行账号)',
    'finance.accountCode.placeholder': '银行账号',
    'finance.registerAddress': 'Bank Address(银行地址)',
    'finance.registerAddress.placeholder': '银行地址'
  },
  CN: {
    'finance.company': '公司名称',
    'finance.companyAddress': '发票寄送地址',
    'finance.companyAddress.placeholder': '发票寄送地址',
    'finance.taxNumber': '纳税人识别号',
    'finance.taxNumber.placeholder': '纳税人识别号',
    'finance.accountBank': '开户银行',
    'finance.accountCode': '开户账号',
    'finance.accountCode.placeholder': '开户账号',
    'finance.registerAddress': '注册地',
    'finance.registerAddress.placeholder': '注册地'
  }
}
