import request from '@/utils/request'

const api = {
  getTaxInfo: '/user/taxInfo',
  updateTaxInfo: '/user/updateTaxInfo',
  adxSettle: '/adxSettle/taxInfo'
}

export default api

export function getTaxInfo () {
  return request({
    url: api.getTaxInfo,
    method: 'get'
  })
}

export function updateTaxInfo (data) {
  return request({
    url: api.updateTaxInfo,
    method: 'post',
    data
  })
}

export function adxSettle () {
  return request({
    url: api.adxSettle,
    method: 'get'
  })
}
