<template>
  <div class="finance-info-container">
    <a-form-model class="finance-info-form" :model="accountForm" labelAlign="right" ref="accountForm" v-bind="layout">
      <a-form-model-item
        prop="company"
        :rules="{
          required: true,
          message: '请输入公司名称',
          trigger: 'blur'
        }"
        :label="language['finance.company']"
      >
        <a-input placeholder="请输入公司名称" v-model.trim="accountForm.company" />
      </a-form-model-item>
      <a-form-model-item
        prop="taxNumber"
        :label="language['finance.taxNumber']"
        :rules="[{
          required: true,
          message: `${'请输入' + language['finance.taxNumber.placeholder']}`,
          trigger: 'blur'
        }]"
      >
        <a-input :placeholder="'请输入' + language['finance.taxNumber.placeholder']" v-model.trim="accountForm.taxNumber" />
      </a-form-model-item>
      <a-form-model-item
        :label="language['finance.accountBank']"
        prop="accountBank"
        :rules="{
          required: true,
          message: `请输入开户银行名称`,
          trigger: 'blur'
        }"
      >
        <a-input placeholder="请输入开户银行名称" v-model.trim="accountForm.accountBank" />
      </a-form-model-item>
      <a-form-model-item
        :label="language['finance.accountCode']"
        prop="accountCode"
        :rules="{
          required: true,
          message: '请输入开户账号',
          trigger: 'blur'
        }"
      >
        <a-input :placeholder="'请输入' + language['finance.accountCode.placeholder']" v-model.trim="accountForm.accountCode" />
      </a-form-model-item>
      <a-form-model-item
        :label="language['finance.registerAddress']"
        prop="registerAddress"
        :rules="{
          required: true,
          message: `${'请输入' + language['finance.registerAddress.placeholder']}`,
          trigger: 'blur'
        }"
      >
        <a-input :placeholder="'请输入' + language['finance.registerAddress.placeholder']" v-model.trim="accountForm.registerAddress" />
      </a-form-model-item>
      <a-form-model-item
        v-if="cur !== 1"
        label="联系电话"
        prop="mobile"
        :rules="{
          required: true,
          message: '请输入联系电话',
          trigger: 'blur'
        }"
      >
        <a-input placeholder="请输入联系电话" v-model.trim="accountForm.mobile" />
      </a-form-model-item>
      <a-form-model-item
        v-if="cur === 1"
        label="Bank Country(银行所在地)"
        prop="bankCountry"
        :rules="{
          required: true,
          message: '请输入银行所在地',
          trigger: 'blur'
        }"
      >
        <a-input placeholder="请输入银行所在地" v-model.trim="accountForm.bankCountry" />
      </a-form-model-item>
      <a-form-model-item
        :label="language['finance.companyAddress']"
        prop="companyAddress"
        :rules="{
          required: true,
          message: `${'请输入' + language['finance.companyAddress.placeholder']}`,
          trigger: 'blur'
        }"
      >
        <a-input :placeholder="'请输入' + language['finance.companyAddress.placeholder']" v-model.trim="accountForm.companyAddress" />
      </a-form-model-item>
      <div class="btn">
        <a-button type="primary" class="primary" @click="handleSubmit">修改</a-button>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import mixGetList from '@/mixins/getListForManage'
import { mapState } from 'vuex'
import languages from './language'
import { getTaxInfo, updateTaxInfo } from '@/apiForManage/financeInfo'
export default {
  mixins: [mixDate, mixGetList],
  data () {
    return {
      accountForm: {},
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 }
      }
    }
  },
  computed: {
    ...mapState({
      cur: (state) => state.user.cur,
      language: (state) => state.user.cur === 1 ? languages.EN : languages.CN
    })
  },
  created () {
    this.getTaxInfoFn()
    this.layout = {
      labelCol: { span: this.cur ? 10 : 6 },
      wrapperCol: { span: this.cur ? 14 : 16 }
    }
  },
  methods: {
    // 获取信息
    async getTaxInfoFn () {
      const res = await getTaxInfo()
      if (res.code === 200 && res.data) {
        const { taxNumber, accountBank, id, accountCode, company, registerAddress, mobile, bankCountry, companyAddress
        } = res.data
        this.accountForm = {
          ...this.accountForm,
          id,
          taxNumber,
          company,
          accountBank,
          accountCode,
          registerAddress,
          companyAddress,
          ...this.cur === 1 ? { bankCountry } : { mobile }
        }
      }
    },
    // 修改信息
    async updateTaxInfoFn (data) {
      const res = await updateTaxInfo(data)
      if (res.code === 200) {
        this.$message.success('修改成功')
        this.getTaxInfoFn()
      }
    },
    handleSubmit () {
      this.$refs.accountForm.validate((validate) => {
        if (!validate) return false
        this.updateTaxInfoFn(this.accountForm)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.finance-info-container {
  margin: 0 @mediumMargin @mediumMargin @mediumMargin;
  background: #fff;
  box-shadow: @modelShadow;
  border-radius: @mediumRadius;
  border: 1px solid @modelBorderColor;
  padding: @smallMargin 36px;
  padding-top: 40px;
  .finance-info-form {
    width: 640px;
    .btn {
      margin-left: 36px;
      margin-top: 40px;
      .primary{
        width: 132px;
      }
    }
  }
}
</style>
