var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"finance-info-container"},[_c('a-form-model',_vm._b({ref:"accountForm",staticClass:"finance-info-form",attrs:{"model":_vm.accountForm,"labelAlign":"right"}},'a-form-model',_vm.layout,false),[_c('a-form-model-item',{attrs:{"prop":"company","rules":{
        required: true,
        message: '请输入公司名称',
        trigger: 'blur'
      },"label":_vm.language['finance.company']}},[_c('a-input',{attrs:{"placeholder":"请输入公司名称"},model:{value:(_vm.accountForm.company),callback:function ($$v) {_vm.$set(_vm.accountForm, "company", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"accountForm.company"}})],1),_c('a-form-model-item',{attrs:{"prop":"taxNumber","label":_vm.language['finance.taxNumber'],"rules":[{
        required: true,
        message: `${'请输入' + _vm.language['finance.taxNumber.placeholder']}`,
        trigger: 'blur'
      }]}},[_c('a-input',{attrs:{"placeholder":'请输入' + _vm.language['finance.taxNumber.placeholder']},model:{value:(_vm.accountForm.taxNumber),callback:function ($$v) {_vm.$set(_vm.accountForm, "taxNumber", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"accountForm.taxNumber"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.language['finance.accountBank'],"prop":"accountBank","rules":{
        required: true,
        message: `请输入开户银行名称`,
        trigger: 'blur'
      }}},[_c('a-input',{attrs:{"placeholder":"请输入开户银行名称"},model:{value:(_vm.accountForm.accountBank),callback:function ($$v) {_vm.$set(_vm.accountForm, "accountBank", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"accountForm.accountBank"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.language['finance.accountCode'],"prop":"accountCode","rules":{
        required: true,
        message: '请输入开户账号',
        trigger: 'blur'
      }}},[_c('a-input',{attrs:{"placeholder":'请输入' + _vm.language['finance.accountCode.placeholder']},model:{value:(_vm.accountForm.accountCode),callback:function ($$v) {_vm.$set(_vm.accountForm, "accountCode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"accountForm.accountCode"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.language['finance.registerAddress'],"prop":"registerAddress","rules":{
        required: true,
        message: `${'请输入' + _vm.language['finance.registerAddress.placeholder']}`,
        trigger: 'blur'
      }}},[_c('a-input',{attrs:{"placeholder":'请输入' + _vm.language['finance.registerAddress.placeholder']},model:{value:(_vm.accountForm.registerAddress),callback:function ($$v) {_vm.$set(_vm.accountForm, "registerAddress", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"accountForm.registerAddress"}})],1),(_vm.cur !== 1)?_c('a-form-model-item',{attrs:{"label":"联系电话","prop":"mobile","rules":{
        required: true,
        message: '请输入联系电话',
        trigger: 'blur'
      }}},[_c('a-input',{attrs:{"placeholder":"请输入联系电话"},model:{value:(_vm.accountForm.mobile),callback:function ($$v) {_vm.$set(_vm.accountForm, "mobile", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"accountForm.mobile"}})],1):_vm._e(),(_vm.cur === 1)?_c('a-form-model-item',{attrs:{"label":"Bank Country(银行所在地)","prop":"bankCountry","rules":{
        required: true,
        message: '请输入银行所在地',
        trigger: 'blur'
      }}},[_c('a-input',{attrs:{"placeholder":"请输入银行所在地"},model:{value:(_vm.accountForm.bankCountry),callback:function ($$v) {_vm.$set(_vm.accountForm, "bankCountry", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"accountForm.bankCountry"}})],1):_vm._e(),_c('a-form-model-item',{attrs:{"label":_vm.language['finance.companyAddress'],"prop":"companyAddress","rules":{
        required: true,
        message: `${'请输入' + _vm.language['finance.companyAddress.placeholder']}`,
        trigger: 'blur'
      }}},[_c('a-input',{attrs:{"placeholder":'请输入' + _vm.language['finance.companyAddress.placeholder']},model:{value:(_vm.accountForm.companyAddress),callback:function ($$v) {_vm.$set(_vm.accountForm, "companyAddress", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"accountForm.companyAddress"}})],1),_c('div',{staticClass:"btn"},[_c('a-button',{staticClass:"primary",attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("修改")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }